<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >

    <b-card class="program-add-wrapper">
      <validation-observer ref="updatePostValidation">
        <!-- form -->
        <b-form>
          <b-row>
            <b-col
              md="12"
            >
              <b-row align-v="center">
                <!-- Category -->
                <b-col md="10">
                  <b-form-group
                    label="Category"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                    >
                      <v-select
                        id="region-edit-vendor"
                        v-model="category"
                        :options="postCategories"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Active -->
                <b-col
                  md="2"
                >
                  <b-form-group
                    class="mt-2"
                  >
                    <b-form-checkbox
                      v-model="isActive"
                      name="check-button"
                      switch
                      inline
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <label for="description-form">Options for translate each post</label>
              <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in countPosts"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          v-if="arrayPosts.length > 1"
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Title -->
                        <b-col md="6">
                          <b-form-group
                            label="Title"
                            label-for="program-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Title"
                              rules="required"
                            >
                              <b-form-input
                                id="program-add-title"
                                v-model="arrayPosts[index - 1].title"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Language code -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            label-for="program-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="program-add-category"
                                v-model="arrayPosts[index - 1].languageCode"
                                item-disabled="disable"
                                :clearable="false"
                                :options="lanCodeOptions"
                                :selectable="(option) => {
                                  return !arrayPosts.find(x => x.languageCode.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Sub description -->
                        <b-col md="12">
                          <b-form-group
                            label="Sub title"
                            label-for="program-add-title"
                            class="mb-2"
                          >
                            <!-- <validation-provider
                              #default="{ errors }"
                              name="Sub title"
                              rules="required"
                            > -->
                            <b-form-input
                              id="program-add-title"
                              v-model="arrayPosts[index - 1].subDescription"
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>
                        <!-- Information -->
                        <b-col md="12">
                          <b-form-group
                            label="Information"
                            label-for="program-add-title"
                            class="mb-2"
                          >
                            <b-form-input
                              id="program-add-title"
                              v-model="arrayPosts[index - 1].distance"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Description"
                          label-for="program-content"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Description"
                            rules="required"
                          >
                            <quill-editor
                              ref="quillEdit"
                              v-model="arrayPosts[index - 1].description"
                              class="editor-form"
                              :options="snowOption"
                              @focus="onEditorFocus($event, index-1)"
                            />
                            <input
                              id="getFile"
                              ref="imageInput"
                              class="d-none"
                              type="file"
                              @change="uploadFunction($event)"
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Default"
                          rules="required"
                        >
                          <b-form-radio
                            v-model="numberDefault"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="index - 1"
                          >
                            Default language
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group>
              <b-col
                cols="12"
                class="pb-2"
              >
                <b-button
                  v-if="lanCodeOptions.length > arrayPosts.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add post form in another language
                </b-button>
              </b-col>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <validation-provider
                  #default="{ errors }"
                  name="Featured image"
                  rules="required"
                >
                  <b-media
                    v-model="imgID"
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                  >
                    <b-media-aside>
                      <b-img
                        ref="refPreviewEl"
                        :src="imgSrc"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />

                    </b-media-aside>
                    <b-media-body>
                      <small class="text-muted">Required image resolution 850x225, image size 5mb.</small>
                      <b-card-text class="my-50">
                        <b-link id="blog-image-text">
                          {{ imgFile ? imgFile.name : 'banner.jpg' }}
                        </b-link>
                      </b-card-text>
                      <div class="d-inline-block">
                        <b-form-file
                          ref="refInputEl"
                          v-model="imgFile"
                          accept=".jpg, .png, .gif"
                          placeholder="Choose file"
                          @change="onFileChange"
                        />
                      </div>
                    </b-media-body>
                  </b-media>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="!$route.params.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="addPost"
              >
                Add program
              </b-button>
              <b-button
                v-if="$route.params.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmUpdatePost"
              >
                Update Local Tips
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'post-manager' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BMediaBody,
  BFormFile,
  BMedia,
  BMediaAside,
  BImg,
  BLink,
  BCardText,
  BOverlay,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
import { slugify, compressedImage, dataURLtoFile } from '@/utils/appUtils'
import { VALID_IMAGE } from '@/utils/config'
import addprograms from './updatePost'

export default {
  components: {
    vSelect,
    BCardBody,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BMediaBody,
    BFormFile,
    BMedia,
    BMediaAside,
    BImg,
    BLink,
    BCardText,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormRadio,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction

              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],
              ['image'],
              ['clean'], ['link']],
            handlers: {
              image() {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      required,
      indexCurrentImage: null,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions', 'statusOptions']),
    ...mapGetters('posts', ['postCategories']),
  },
  // async created() {
  //   await store.dispatch('program/fetchCategories')
  // },
  setup() {
    const backUpOptions = store.state.app.lanCodeOptions
    const {
      arrayPosts,
      countPosts,
      imgID,
      imgFile,
      imgSrc,
      category,
      defaultTitle,
      defaultDescription,
      defaultSubDescription,
      status,
      distance,
      loading,
      numberDefault,
      slug,
      isActive,
    } = addprograms()
    return {
      backUpOptions,
      router,
      arrayPosts,
      countPosts,
      imgID,
      imgFile,
      imgSrc,
      category,
      defaultTitle,
      defaultDescription,
      defaultSubDescription,
      status,
      distance,
      loading,
      numberDefault,
      slug,
      isActive,
    }
  },
  methods: {
    onEditorFocus(quill, index) {
      this.$refs.imageInput.value = null
      const fileInput = document.getElementById('imageInput')
      if (fileInput) {
        fileInput.value = ''
      }
      console.log('hello')
      this.indexCurrentImage = index
    },
    uploadFunction(e) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('name', 'hehe')
      store.dispatch('program/uploadImage', formData).then(response => {
        const imageKey = response.body.key
        console.log(this.indexCurrentImage)
        const range = this.$refs.quillEdit[this.indexCurrentImage].quill.getSelection()
        this.$refs.quillEdit[this.indexCurrentImage].quill.insertEmbed(range.index, 'image', `${VALID_IMAGE}${imageKey}`)
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload image fail',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        this.loading = false
      })
    },
    onFileChange(e) {
      const { files } = e.target
      if (!files.length) {
        return
      }
      this.compressedImage(files[0])
      this.imgSrc = e.target.result
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imgSrc = await theReader.result
      }
      theReader.readAsDataURL(files[0])
    },
    compressedImage(file) {
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        const reader = new FileReader()
        reader.onload = readerEvent => {
          const image = new Image()
          image.onload = () => {
            const canvas = document.createElement('canvas')
            const maxSize = 544; let
              { width } = image
            let { height } = image
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width
                width = maxSize
              }
            } else if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            const dataUrl = canvas.toDataURL('image/jpeg')
            this.imgFile = dataURLtoFile(dataUrl, file.name)
          }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    addNewItemInItemFormDescription() {
      this.arrayPosts.push({
        title: '',
        languageCode: '',
        subDescription: '',
        description: '',
        disance: '',
      })
      this.countPosts += 1
    },
    removeItemDescription(index) {
      this.arrayPosts.splice(index, 1)
      this.countPosts -= 1
    },
    confirmUpdatePost() {
      this.$refs.updatePostValidation.validate().then(success => {
        if (success) {
          this.loading = true
          if (this.imgFile) {
            const formData = new FormData()
            formData.append('file', this.imgFile)
            formData.append('name', 'hehe')
            store.dispatch('program/uploadImage', formData).then(response => {
              this.imgID = response.body.id
              this.updatePost()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
          } else {
            this.updatePost()
          }
        }
      })
    },
    updatePost() {
      let arrPostPayload = []
      let postDefault = null
      if (this.arrayPosts.length) {
        postDefault = { ...this.arrayPosts[this.numberDefault] }
        postDefault.isDefaultLanguage = true
        postDefault.languageCode = postDefault.languageCode.value
        arrPostPayload = [...this.arrayPosts]
        arrPostPayload.splice(this.numberDefault, 1)
      }
      const payload = {
        title: postDefault.title,
        distance: postDefault.distance,
        subDescription: postDefault.subDescription,
        description: postDefault.description,
        isActive: this.isActive,
        categoryId: this.category.value,
        imageId: this.imgID,
        translations: arrPostPayload.length ? arrPostPayload.map(x => ({
          title: x.title,
          subDescription: x.subDescription,
          description: x.description,
          distance: x.distance,
          languageCode: x.languageCode.value ? x.languageCode.value : x.languageCode,
          isDefaultLanguage: false,
        })) : [],
      }
      if (postDefault) {
        payload.translations.push(postDefault)
      }
      store.dispatch('posts/updatePost', { id: this.$route.params.id, post: payload }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Local Tips successfuly',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.loading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
    display: none;
}
</style>
