import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { VALID_IMAGE } from '@/utils/config'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function addProduct() {
  const toast = useToast()
  const category = ref(null)
  const slug = ref(null)
  const status = ref(null)
  const isActive = ref(false)
  const distance = ref(null)
  const imgID = ref(null)
  const imgFile = ref(null)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const defaultTitle = ref('')
  const defaultDescription = ref('')
  const defaultSubDescription = ref('')
  const loading = ref(false)
  const arrayPosts = ref([
    {
      title: '',
      languageCode: '',
      subDescription: '',
      description: '',
      distance: '',
    },
  ])
  const countPosts = ref(1)
  const idProgram = ref(null)
  const numberDefault = ref(null)
  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idProgram.value = router.currentRoute.params.id
    store.dispatch('posts/fetchCategories').then(() => {
      store
        .dispatch('posts/fetchPost', { id: idProgram.value })
        .then(response => {
          const res = response.body
          category.value = {
            label: store.state.posts.postCategories.find(x => x.value === res.categoryId)
              ? store.state.posts.postCategories.find(x => x.value === res.categoryId).label : 'None',
            value: res.categoryId,
          }
          status.value = {
            label: store.state.app.statusOptions.find(x => x.value === res.status).label,
            value: res.status,
          }
          slug.value = res.slug
          isActive.value = res.isActive
          if (res.image) {
            imgSrc.value = VALID_IMAGE + res.image.key
            imgID.value = res.image.id
          }
          countPosts.value = res.translations.length
          arrayPosts.value = res.translations.map(x => ({
            title: x.title,
            languageCode: store.state.app.lanCodeOptions.find(e => e.value === x.languageCode),
            subDescription: x.subDescription,
            description: x.description,
            distance: x.distance,
            isDefaultLanguage: x.isDefaultLanguage,
          })).reverse()
          defaultTitle.value = res.title
          defaultSubDescription.value = res.subDescription
          defaultDescription.value = res.description
          distance.value = res.distance
          numberDefault.value = arrayPosts.value.findIndex(x => x.isDefaultLanguage)

          loading.value = false
        }).catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          loading.value = false
        })
    })
  }

  return {
    arrayPosts,
    countPosts,
    imgID,
    imgFile,
    imgSrc,
    category,
    defaultTitle,
    defaultDescription,
    defaultSubDescription,
    status,
    distance,
    loading,
    numberDefault,
    slug,
    isActive,
  }
}
