const dataURItoBlob = dataURI => {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1])
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i)
  return new Blob([ia], { type: mime })
}

// eslint-disable-next-line import/prefer-default-export
export const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')

  const resize = () => {
    let { width, height } = image

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    } else if (height > maxSize) {
      width *= maxSize / height
      height = maxSize
    }

    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(image, 0, 0, width, height)
    console.log(canvas.toBlob)
    const dataUrl = canvas.toDataURL('image/jpeg')

    return dataURItoBlob(dataUrl)
  }

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'))
      return
    }

    reader.onload = readerEvent => {
      image.onload = () => ok(resize())
      image.src = readerEvent.target.result
    }

    reader.readAsDataURL(file)
  })
}
export const blobToFile = (theBlob, fileName) => {
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  return theBlob
}

// export const getDates = (startDate, endDate) => {
//   const dates = []
//   let currentDate = startDate
//   const addDays = days => {
//     const date = new Date(this.valueOf())
//     date.setDate(date.getDate() + days)
//     return date
//   }
//   while (currentDate <= endDate) {
//     dates.push(currentDate)
//     currentDate = addDays.call(currentDate, 1)
//   }
//   return dates
// }
export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime())

  const dates = []

  while (date <= endDate) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export const getDates = (startDate, stopDate) => {
  const dateArray = new Array()
  let currentDate = startDate
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate))
    currentDate = currentDate.addDays(1)
  }
  return dateArray
}

export const slugify = str => str
  .toLowerCase()
  .trim()
  .replace(/[^\w\s-]/g, '')
  .replace(/[\s_-]+/g, '-')
  .replace(/^-+|-+$/g, '')

export const dataURLToBlob = dataURL => {
  const BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(',')
    var contentType = parts[0].split(':')[1]
    var raw = parts[1]

    return new Blob([raw], { type: contentType })
  }

  var parts = dataURL.split(BASE64_MARKER)
  var contentType = parts[0].split(':')[1]
  var raw = window.atob(parts[1])
  const rawLength = raw.length

  const uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
// export const compressedImage = file => {
//   // Ensure it's an image
//   if (file.type.match(/image.*/)) {
//     // Load the image
//     const reader = new FileReader()
//     reader.onload = readerEvent => {
//       const image = new Image()
//       image.onload = () => {
//         const canvas = document.createElement('canvas')
//         const maxSize = 544; let
//           { width } = image
//         let { height } = image
//         if (width > height) {
//           if (width > maxSize) {
//             height *= maxSize / width
//             width = maxSize
//           }
//         } else if (height > maxSize) {
//           width *= maxSize / height
//           height = maxSize
//         }
//         canvas.width = width
//         canvas.height = height
//         canvas.getContext('2d').drawImage(image, 0, 0, width, height)
//         const dataUrl = canvas.toDataURL('image/jpeg')
//         console.log(dataURLtoFile(dataUrl, 'hehe'))
//         return dataURLtoFile(dataUrl, 'hehe')
//       }
//       image.src = readerEvent.target.result
//     }
//     reader.readAsDataURL(file)
//   }
// }
export const getFileFromBase64 = (string64, fileName) => {
  const trimmedString = string64.replace('dataimage/jpegbase64', '')
  const imageContent = window.atob(trimmedString)
  const buffer = new ArrayBuffer(imageContent.length)
  const view = new Uint8Array(buffer)

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n)
  }
  const type = 'image/jpeg'
  const blob = new Blob([buffer], { type })
  return new File([blob], fileName, { lastModified: new Date().getTime(), type })
}
